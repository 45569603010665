<template>
  <transition
    enter-active-class="transition duration-300 ease-out transform"
    enter-from-class="translate-y-20 opacity-0 sm:translate-y-0 sm:translate-x-20"
    enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
    leave-active-class="transition duration-100 ease-in"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      v-if="show"
      class="z-10 w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-lg pointer-events-auto  ring-1 ring-black ring-opacity-5"
    >
      <div class="p-4">
        <div class="flex items-start">
          <div class="flex-shrink-0">
            <CheckCircleIcon
              class="w-6 h-6 text-green-400"
              aria-hidden="true"
            />
          </div>
          <div class="ml-3 w-0 flex-1 pt-0.5">
            <p class="text-sm font-medium text-secondary-900">
              {{ modelValue.heading }}
            </p>
            <p class="mt-1 text-sm text-secondary-500">
              {{ modelValue.text }}
            </p>
          </div>
          <div class="flex flex-shrink-0 ml-4">
            <button
              @click="show = false"
              class="inline-flex bg-white rounded-md  text-secondary-400 hover:text-secondary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
            >
              <span class="sr-only">Close</span>
              <XIcon class="w-5 h-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { ref, watch } from "vue";
import { CheckCircleIcon } from "@heroicons/vue/outline";
import { XIcon } from "@heroicons/vue/solid";

export default {
  components: {
    CheckCircleIcon,
    XIcon,
  },
  props: ["modelValue"],
  setup(props, { emit }) {
    const show = ref(props.modelValue.show);

    watch(show, function (payload) {
      emit("update:modelValue", payload);
    });

    return {
      show,
    };
  },
};
</script>