import { createStore } from "vuex";

import authModule from "./modules/auth/index.js";
import docModule from "./modules/doc/index.js";
import notificationModule from "./modules/notification/index.js";
import searchtreeModule from "./modules/searchtree/index.js";
import userModule from "./modules/user/index.js";

const store = createStore({
  modules: {
    auth: authModule,
    doc: docModule,
    notification: notificationModule,
    searchtree: searchtreeModule,
    user: userModule,
  },
});

export default store;
