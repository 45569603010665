<template>
  <li>
    <div
      :class="{
        'text-primary-600': showChildren,
        'cursor-pointer font-bold': hasChildren,
        'border-t border-solid border-secondary-300': hasSeparator,
      }"
      class="flex items-center px-4 py-3 text-secondary-600"
      @click="toggleChildren"
    >
      <div class="flex items-center flex-grow">
        <FolderOpenIcon v-if="hasChildren" class="w-6 h-6" aria-hidden="true" />
        <FolderIcon v-else class="w-6 h-6" aria-hidden="true" />
        <div class="text-sm ml-1.5">{{ name }}</div>
        <span
          v-if="count"
          class="
            inline-flex
            items-center
            px-2
            py-0.5
            rounded-full
            text-xs
            font-medium
            bg-primary-600
            text-white
            ml-1
          "
        >
          {{ count }}
        </span>
      </div>
      <button
        v-if="count"
        type="button"
        class="text-primary-600 focus:ring-2 focus:outline-none"
        @click.stop="search(name)"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          class="w-6 h-6"
        >
          <path
            class="fill-current"
            d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2h-1.528A6 6 0 004 9.528V4z"
          ></path>
          <path
            class="fill-secondary-900"
            fill-rule="evenodd"
            d="M8 10a4 4 0 00-3.446 6.032l-1.261 1.26a1 1 0 101.414 1.415l1.261-1.261A4 4 0 108 10zm-2 4a2 2 0 114 0 2 2 0 01-4 0z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </button>
      <DocumentSearchIcon
        v-else
        class="w-6 h-6 text-gray-300 focus:ring-2 focus:outline-none"
        :class="{ 'mr-6': !hasChildren && isRootLvl }"
        aria-hidden="true"
      />
      <div v-if="hasChildren">
        <ChevronUpIcon v-if="showChildren" class="w-6 h-6" aria-hidden="true" />
        <ChevronDownIcon v-else class="w-6 h-6" aria-hidden="true" />
      </div>
    </div>
    <ul class="bg-secondary-50 pl-7">
      <tree-item
        v-show="showChildren"
        v-for="(item, index) in children"
        :key="item.name"
        :parent="name"
        :children="item.children"
        :name="item.name"
        :count="item.count"
        :index="index"
        :isRootLvl="false"
      >
      </tree-item>
    </ul>
  </li>
</template>
<script>
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { ref, computed } from "vue";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  FolderIcon,
  FolderOpenIcon,
  DocumentSearchIcon,
} from "@heroicons/vue/solid";

export default {
  props: ["name", "parent", "children", "count", "index", "isRootLvl"],
  name: "tree-item",
  components: {
    ChevronDownIcon,
    ChevronUpIcon,
    FolderIcon,
    FolderOpenIcon,
    DocumentSearchIcon,
  },
  setup(props) {
    const showChildren = ref(false);
    const router = useRouter();
    const store = useStore();

    const hasSeparator = computed(() => {
      return !props.isRootLvl && props.index && props.index !== 0;
    });

    const hasChildren = computed(() => {
      return !!props?.children?.length;
    });

    function toggleChildren() {
      if (props.children) {
        showChildren.value = !showChildren.value;
      }
    }

    function search(value) {
      let searchObject = {
        collections: {
          docs: true,
          emails: true,
          cad: true,
        },
        reminder: "",
        filters: [
          {
            bool_field: "document_types",
            bool_value: value,
            bool_operator: "AND",
          },
        ],
        fields: [
          "_id",
          "title",
          "created",
          "email_subject",
          "drawing_number",
          "document_types",
          "PDF",
          "OFILE",
          "PAGE1",
          "reminder",
          "_collection",
        ],
      };

      if (props.parent) {
        searchObject.filters.push({
          bool_field: "document_types",
          bool_value: props.parent,
          bool_operator: "AND",
        });
      }

      searchObject.sort = "created.date:desc";

      // console.log(searchObject);

      store.dispatch("doc/search", {
        search: searchObject,
        page: 1,
        size: 20,
      });
      router.push("/docs");
    }

    return {
      showChildren,
      hasChildren,
      toggleChildren,
      hasSeparator,
      search,
    };
  },
};
</script>