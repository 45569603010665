export default {
  docs(state) {
    return state.docs;
  },
  activeDoc(state) {
    return state.activeDoc;
  },
  lastSearchObject(state) {
    return state.lastSearchObject;
  },
  totalDocs(state) {
    return state.totalDocs;
  },
  page(state) {
    return state.page;
  },
  size(state) {
    return state.size;
  },
  viewType(state) {
    return state.viewType;
  },
  sortType(state) {
    return state.sortType;
  },
};
