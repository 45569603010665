<template>
  <div>
    <div class="flex">
      <Popover class="relative flex-grow" v-slot="{ open }">
        <div class="flex items-center justify-center">
          <div class="flex flex-grow">
            <div class="flex-grow">
              <label for="search" class="sr-only">{{
                t("searchbar.search")
              }}</label>
              <div class="relative rounded-md shadow-sm">
                <div
                  class="
                    absolute
                    inset-y-0
                    left-0
                    flex
                    items-center
                    pl-3
                    pointer-events-none
                  "
                >
                  <SearchIcon
                    class="w-5 h-5 text-secondary-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  type="search"
                  name="search"
                  id="search"
                  class="
                    block
                    w-full
                    min-w-full
                    pl-10
                    rounded-md
                    sm:text-sm
                    border-secondary-300
                    focus:ring-primary-500
                    focus:border-primary-500
                  "
                  :placeholder="t('searchbar.search')"
                  autocomplete="off"
                  v-model.trim="searchBar"
                  @keyup.enter="searchDocs"
                />
              </div>
            </div>

            <button
              class="
                inline-flex
                items-center
                px-4
                py-2
                ml-3
                text-sm
                font-medium
                text-white
                border border-transparent
                rounded-md
                shadow-sm
                bg-primary-600
                hover:bg-primary-700
                focus:outline-none
                focus:ring-2 focus:ring-offset-2 focus:ring-primary-500
              "
              @click="searchDocs"
              type="button"
            >
              {{ t("searchbar.search") }}
            </button>
          </div>

          <div class="ml-3 min-w-64">
            <PopoverButton
              :class="[
                open ? 'text-secondary-900' : 'text-secondary-500',
                'inline-flex items-center px-3 py-2 border border-secondary-300 shadow-sm sm:text-sm leading-4 font-medium rounded-md text-secondary-600 bg-white hover:bg-secondary-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500',
              ]"
            >
              <span>{{ t("searchbar.refine_search") }}</span>
              <ChevronDownIcon
                :class="[
                  open ? 'text-secondary-600' : 'text-secondary-400',
                  'ml-2 h-5 w-5 group-hover:text-secondary-500',
                ]"
                aria-hidden="true"
              />
            </PopoverButton>
          </div>

          <div class="ml-3 bg-gray-100 p-0.5 rounded-lg flex items-center">
            <button
              type="button"
              @click="setViewType('list')"
              class="
                p-1.5
                rounded-md
                focus:outline-none
                focus:ring-2 focus:ring-inset focus:ring-primary-500
              "
              :class="[
                viewType === 'list'
                  ? 'bg-primary-600 shadow-sm text-white'
                  : 'hover:bg-white hover:shadow-sm text-gray-400',
              ]"
            >
              <ViewListIcon class="w-5 h-5" aria-hidden="true" />
              <span class="sr-only">Use list view</span>
            </button>
            <button
              type="button"
              @click="setViewType('grid')"
              class="
                ml-0.5
                p-1.5
                rounded-md
                focus:outline-none
                focus:ring-2 focus:ring-inset focus:ring-primary-500
              "
              :class="[
                viewType === 'grid'
                  ? 'bg-primary-600 shadow-sm text-white'
                  : 'hover:bg-white hover:shadow-sm text-gray-400',
              ]"
            >
              <ViewGridIconSolid class="w-5 h-5" aria-hidden="true" />
              <span class="sr-only">Use grid view</span>
            </button>
            <button
              type="button"
              @click="setViewType('cover')"
              class="
                ml-0.5
                p-1.5
                rounded-md
                focus:outline-none
                focus:ring-2 focus:ring-inset focus:ring-primary-500
              "
              :class="[
                viewType === 'cover'
                  ? 'bg-primary-600 shadow-sm text-white'
                  : 'hover:bg-white hover:shadow-sm text-gray-400',
              ]"
            >
              <PhotographIconSolid class="w-5 h-5" aria-hidden="true" />
              <span class="sr-only">Use cover view</span>
            </button>
          </div>

          <Menu as="div" class="relative inline-block text-left">
            <div>
              <MenuButton
                class="
                  inline-flex
                  justify-center
                  text-sm
                  font-medium
                  text-gray-700
                  group
                  hover:text-gray-900
                "
              >
                <component
                  :is="sortType.icon"
                  class="
                    self-center
                    w-4
                    h-4
                    text-gray-400
                    group-hover:text-gray-500
                  "
                ></component>
                <span
                  class="self-center text-gray-400 group-hover:text-gray-500"
                >
                  {{ t(sortType.name) }}
                </span>
                <FilterIcon
                  class="
                    flex-shrink-0
                    w-5
                    h-5
                    ml-1
                    -mr-1
                    text-gray-400
                    group-hover:text-gray-500
                  "
                  aria-hidden="true"
                />
              </MenuButton>
            </div>

            <transition
              enter-active-class="transition duration-100 ease-out"
              enter-from-class="transform scale-95 opacity-0"
              enter-to-class="transform scale-100 opacity-100"
              leave-active-class="transition duration-75 ease-in"
              leave-from-class="transform scale-100 opacity-100"
              leave-to-class="transform scale-95 opacity-0"
            >
              <MenuItems
                class="
                  absolute
                  right-0
                  w-40
                  mt-2
                  origin-top-right
                  bg-white
                  rounded-md
                  shadow-2xl
                  ring-1 ring-black ring-opacity-5
                  focus:outline-none
                "
              >
                <div class="py-1">
                  <MenuItem
                    v-for="option in sortOptions"
                    :key="option.name"
                    v-slot="{ active }"
                    @click="setSortType(option)"
                  >
                    <a
                      :class="[
                        option.sort === sortType.sort
                          ? 'font-medium text-gray-900'
                          : 'text-gray-500',
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm',
                      ]"
                    >
                      <span class="flex">
                        <component
                          :is="option.icon"
                          class="
                            self-center
                            w-4
                            h-4
                            text-gray-300
                            group-hover:text-gray-400
                          "
                        ></component>
                        <span class="self-center">{{ t(option.name) }}</span>
                      </span>
                    </a>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </div>

        <transition
          enter-active-class="transition duration-200 ease-out"
          enter-from-class="-translate-y-1 opacity-0"
          enter-to-class="translate-y-0 opacity-100"
          leave-active-class="transition duration-150 ease-in"
          leave-from-class="translate-y-0 opacity-100"
          leave-to-class="-translate-y-1 opacity-0"
        >
          <PopoverPanel
            class="
              absolute
              inset-x-0
              z-10
              mt-2
              origin-top-right
              transform
              shadow-lg
            "
          >
            <div class="bg-white rounded">
              <div
                class="
                  flex
                  justify-center
                  px-4
                  py-6
                  mx-auto
                  max-w-7xl
                  sm:px-6
                  sm:py-8
                  lg:px-8
                "
              >
                <base-toggle v-model="collection_emails" class="mr-12">
                  <template v-slot:label>Email</template>
                </base-toggle>
                <base-toggle v-model="collection_docs" class="mr-12">
                  <template v-slot:label>Docs</template>
                </base-toggle>
                <base-toggle v-model="collection_cad" class="mr-12">
                  <template v-slot:label>CAD</template>
                </base-toggle>
                <base-toggle v-model="collection_norm">
                  <template v-slot:label>Norms</template>
                </base-toggle>
              </div>
              <div
                class="
                  flex
                  justify-center
                  px-4
                  pb-6
                  mx-auto
                  max-w-7xl
                  sm:px-6
                  sm:pb-8
                  lg:px-8
                "
              >
                <span class="relative z-0 inline-flex rounded-md shadow-sm">
                  <button
                    v-for="(reminder, reminderIdx) in reminderOptions"
                    :key="reminder.name"
                    :value="reminder"
                    type="button"
                    class="
                      relative
                      inline-flex
                      items-center
                      px-4
                      py-2
                      text-sm
                      font-medium
                      bg-white
                      border border-gray-300
                      focus:z-10
                      focus:outline-none
                      focus:ring-1 focus:ring-primary-500
                      focus:border-primary-500
                    "
                    :class="[
                      reminderIdx === 0 ? 'rounded-l-md' : '',
                      reminderIdx === reminderOptions.length - 1
                        ? 'rounded-r-md'
                        : '',
                      reminderSelected &&
                      reminder.name === reminderSelected.name
                        ? 'bg-primary-600 hover:bg-primary-700 text-white'
                        : 'hover:bg-gray-50 text-gray-700',
                    ]"
                    @click="setReminder(reminder)"
                  >
                    {{ reminder.name }}
                  </button>
                </span>
              </div>
              <div class="px-4 pb-6 mx-auto max-w-7xl sm:px-6 sm:pb-8 lg:px-8">
                <doc-search-filter
                  v-for="filter in searchFilters"
                  :key="filter.id"
                  :filterObject="filter"
                  :searchFields="filteredSearchfields"
                  @filterChanged="changeSearchFilters"
                  @filterDelete="deleteSearchFilter"
                ></doc-search-filter>

                <div class="mt-3 text-right">
                  <button
                    @click="addSearchFilter"
                    type="button"
                    class="
                      inline-flex
                      items-center
                      px-3
                      py-2
                      text-sm
                      font-medium
                      text-white
                      border border-transparent
                      rounded-md
                      shadow-sm
                      bg-primary-600
                      hover:bg-primary-700
                      focus:outline-none
                      focus:ring-2 focus:ring-offset-2 focus:ring-primary-500
                    "
                  >
                    <PlusIcon class="w-6 h-6" aria-hidden="true" />
                    {{ t("searchbar.add_filter") }}
                  </button>
                </div>
              </div>
            </div>
          </PopoverPanel>
        </transition>
      </Popover>
    </div>
  </div>
</template>


<script>
import { ref, computed, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/vue";
import {
  SearchIcon,
  ChevronDownIcon,
  FilterIcon,
  PlusIcon,
  ViewListIcon,
  ViewGridIcon as ViewGridIconSolid,
  PhotographIcon as PhotographIconSolid,
  ArrowNarrowUpIcon,
  ArrowNarrowDownIcon,
} from "@heroicons/vue/solid";
import BaseToggle from "@/components/ui/BaseToggle.vue";
import DocSearchFilter from "./DocSearchFilter.vue";
import { useI18n } from "vue-i18n";

const currentDate = new Date();

const searchFields = [
  {
    field: "title",
    searchFields: ["title", "TITE", "TITA", "TITF", "TITD"],
    collections: ["docs", "emails"],
    sortable: true,
  },
  {
    field: "subtitle",
    searchFields: "subtitle",
    collections: ["docs", "emails"],
    sortable: true,
  },
  {
    field: "language",
    searchFields: "language",
    collections: ["docs", "emails"],
  },
  {
    field: "status",
    searchFields: "status",
    collections: ["docs", "emails"],
  },
  {
    field: "document_types",
    searchFields: "document_types",
    collections: ["docs", "emails"],
    sortable: true,
  },
  {
    field: "keywords.text",
    searchFields: "keywords.text",
    collections: ["docs", "emails"],
    sortable: true,
  },
  {
    field: "frequent_words.text",
    searchFields: "frequent_words.text",
    collections: ["docs", "emails"],
    sortable: true,
  },
  {
    field: "fulltext",
    searchFields: "fulltext",
    collections: ["docs", "emails"],
  },
  {
    field: "reminder",
    searchFields: "reminder",
    collections: ["docs", "emails", "cad"],
    sortable: true,
  },
  {
    field: "email_from",
    searchFields: "email_from",
    collections: ["emails"],
    sortable: true,
  },
  {
    field: "email_to",
    searchFields: "email_to",
    collections: ["emails"],
    sortable: true,
  },
  {
    field: "email_subject",
    searchFields: "email_subject",
    collections: ["emails"],
    sortable: true,
  },
  {
    field: "originator",
    searchFields: "originator",
    collections: ["docs", "emails"],
    sortable: true,
  },
  {
    field: "access_groups",
    searchFields: "access_groups",
    collections: ["docs", "emails"],
    sortable: true,
  },
  {
    field: "created.date",
    searchFields: "created.date",
    collections: ["docs", "emails"],
    sortable: true,
  },
  {
    field: "updated.date",
    searchFields: "updated.date",
    collections: ["docs", "emails"],
    sortable: true,
  },
];
const reminderOptions = [
  {
    name: "2 Weeks",
    date: new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + 14
    ),
  },
  {
    name: "4 Weeks",
    date: new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + 28
    ),
  },
  {
    name: "3 Months",
    date: new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 3,
      currentDate.getDate()
    ),
  },
  {
    name: "6 Months",
    date: new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 6,
      currentDate.getDate() + 1
    ),
  },
  {
    name: "1 Year",
    date: new Date(
      currentDate.getFullYear() + 1,
      currentDate.getMonth(),
      currentDate.getDate() + 1
    ),
  },
  {
    name: "2 Years",
    date: new Date(
      currentDate.getFullYear() + 2,
      currentDate.getMonth(),
      currentDate.getDate() + 1
    ),
  },
  {
    name: "20 Years for debugging",
    date: new Date(
      currentDate.getFullYear() + 20,
      currentDate.getMonth(),
      currentDate.getDate() + 1
    ),
  },
];

export default {
  components: {
    PlusIcon,
    SearchIcon,
    Popover,
    PopoverButton,
    PopoverPanel,
    ChevronDownIcon,
    FilterIcon,
    BaseToggle,
    DocSearchFilter,
    ViewListIcon,
    ViewGridIconSolid,
    PhotographIconSolid,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const { t } = useI18n();

    const searchBar = ref("");
    let filteredSearchfields = ref([""]);
    const collection_emails = ref(true);
    const collection_docs = ref(true);
    const collection_cad = ref(true);
    const collection_norm = ref(true);
    const searchFilters = ref([
      {
        id: 1,
      },
    ]);
    const reminderSelected = ref(null);

    const sortOptions = computed(function () {
      let options = [];
      searchFields
        .filter((f) => f.sortable)
        .forEach((field) => {
          options.push({
            name: field.field,
            sort: field.field + ":desc",
            icon: ArrowNarrowDownIcon,
          });
          options.push({
            name: field.field,
            sort: field.field + ":asc",
            icon: ArrowNarrowUpIcon,
          });
        });
      return options;
    });
    const sortType = computed(function () {
      return store.getters["doc/sortType"];
    });
    const viewType = computed(function () {
      return store.getters["doc/viewType"];
    });

    const totalDocs = computed(function () {
      return store.getters["doc/totalDocs"];
    });

    const lastSearchObject = computed(function () {
      return store.getters["doc/lastSearchObject"];
    });

    function searchDocs() {
      // console.log(searchBar.value);
      let searchObject = {
        collections: {
          docs: collection_docs.value,
          emails: collection_emails.value,
          cad: collection_cad.value,
          norm: collection_norm.value,
        },
        filters: [
        ],
        fields: [
          "_id",
          "title",
          "TITD",
          "TITE",
          "created",
          "email_subject",
          "drawing_number",
          "document_types",
          "PDF",
          "REFD",
          "DNUM",
          "OFILE",
          "PAGE1",
          "reminder",
          "_collection",
        ],
      };

      if (searchBar.value !== "") {
        searchObject.filters.push({
          bool_field: "title",
          bool_value: searchBar.value,
          bool_operator: "OR",
        });
        searchObject.filters.push({
          bool_field: "TITD",
          bool_value: searchBar.value,
          bool_operator: "OR",
        });
        searchObject.filters.push({
          bool_field: "TITE",
          bool_value: searchBar.value,
          bool_operator: "OR",
        });
        searchObject.filters.push({
          bool_field: "KW",
          bool_value: searchBar.value,
          bool_operator: "OR",
        });
        searchObject.filters.push({
          bool_field: "fulltext",
          bool_value: searchBar.value,
          bool_operator: "OR",
        });
        searchObject.filters.push({
          bool_field: "drawing_number",
          bool_value: searchBar.value,
          bool_operator: "OR",
        });
      }

      if (reminderSelected.value && reminderSelected.value.date) {
        searchObject.reminder = reminderSelected.value.date;
      }

      searchFilters.value.forEach((filter) => {
        if (
          typeof filter.bool_value !== "undefined" &&
          filter.bool_value !== ""
        ) {
          let filterName = filter.bool_field;
          
          let actualSearchFields = searchFields
            .filter(f => f.field === filterName)
            .map(f => f.searchFields)
            .shift();
          searchObject.filters.push({
            bool_field: actualSearchFields,
            bool_operator: filter.bool_operator,
            bool_value: filter.bool_value,
          });
        }
      });

      if (sortType.value) {
        searchObject.sort = sortType.value.sort;
      }

      console.log(searchObject);
      store.dispatch("doc/search", {
        search: searchObject,
        page: 1,
        size: 15,
      });
      setViewType("list");
      router.push("/docs");
    }

    watch(collection_emails, function () {
      filterSearchfields();
    });

    watch(collection_docs, function () {
      filterSearchfields();
    });

    watch(collection_cad, function () {
      filterSearchfields();
    });

    watch(collection_norm, function () {
      filterSearchfields();
    });

    function filterSearchfields() {
      let activeCollections = [];

      if (collection_docs.value) {
        activeCollections.push("docs");
      }
      if (collection_emails.value) {
        activeCollections.push("emails");
      }
      if (collection_cad.value) {
        activeCollections.push("cad");
      }
      if (collection_norm.value) {
        activeCollections.push("norm");
      }

      filteredSearchfields.value = searchFields
        .filter((option) => {
          return activeCollections.some((collection) =>
            option.collections.includes(collection)
          );
        })
        .map((option) => option.field);
    }
    filterSearchfields();

    function changeSearchFilters(payload) {
      // console.log(payload);
      const indexToAlter = searchFilters.value.findIndex((obj) => {
        return obj.id === payload.id;
      });
      searchFilters.value[indexToAlter] = payload;
      // console.log(searchFilters.value);
    }

    function addSearchFilter() {
      searchFilters.value.push({
        id: new Date().toISOString(),
      });
    }

    function deleteSearchFilter(payload) {
      const filterId = payload.id;

      searchFilters.value = searchFilters.value.filter((item) => {
        return item.id !== filterId;
      });
    }

    function setReminder(reminder) {
      console.log(reminder);
      console.log(reminderSelected.value);
      if (
        reminderSelected.value != null &&
        reminderSelected.value.date === reminder.date
      ) {
        reminderSelected.value = null;
      } else {
        reminderSelected.value = reminder;
      }
    }

    function setViewType(type) {
      store.dispatch("doc/setViewType", type);
    }

    function setSortType(type) {
      store.dispatch("doc/setSortType", type);

      if (lastSearchObject.value !== null) {
        let searchObject = lastSearchObject.value;
        searchObject.sort = type.sort;
        console.log(searchObject);
        store.dispatch("doc/search", {
          search: searchObject,
          page: 1,
          size: 15,
        });
      }
    }

    return {
      t,
      searchBar,
      searchDocs,
      filteredSearchfields,
      totalDocs,
      lastSearchObject,
      searchFields,
      collection_emails,
      collection_docs,
      collection_cad,
      collection_norm,
      changeSearchFilters,
      searchFilters,
      addSearchFilter,
      deleteSearchFilter,
      reminderSelected,
      reminderOptions,
      sortOptions,
      sortType,
      setSortType,
      setReminder,
      viewType,
      setViewType,
    };
  },
};
</script>