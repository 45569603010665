<template>
  <div class="flex justify-center min-h-screen">
    <div class="flex flex-col justify-center w-full max-w-md space-y-8">
      <div class="">
        <div class="text-center">
          <img
            src="@/assets/logo.png"
            class="inline-block w-full h-auto max-w-xs p-2"
          />
        </div>
        <h2 class="mt-6 text-3xl font-extrabold text-center text-secondary-900">
          Sign in to your account
        </h2>

        <form class="mt-8 space-y-6" @submit.prevent="submitForm">
          <input type="hidden" name="remember" value="true"/>
          <div class="-space-y-px rounded-md shadow-sm">
            <div>
              <label for="email-address" class="sr-only"
                >User name or Email address</label
              >
              <input
                v-model.trim="email"
                id="email-address"
                name="email"
                type="text"
                autocomplete="email"
                required=""
                class="relative block w-full px-3 py-2 border rounded-none appearance-none  border-secondary-300 placeholder-secondary-500 text-secondary-900 rounded-t-md focus:outline-none focus:ring-primary-500 focus:border-primary-500 focus:z-10 sm:text-sm"
                placeholder="User name Email address"
              />
            </div>
            <div>
              <label for="password" class="sr-only">Password</label>
              <input
                v-model.trim="password"
                id="password"
                name="password"
                type="password"
                autocomplete="current-password"
                required=""
                class="relative block w-full px-3 py-2 border rounded-none appearance-none  border-secondary-300 placeholder-secondary-500 text-secondary-900 rounded-b-md focus:outline-none focus:ring-primary-500 focus:border-primary-500 focus:z-10 sm:text-sm"
                placeholder="Password"
              />
            </div>
            <p v-if="!formIsValid">
              Please enter a valid email and password (must be at least 6
              characters long).
            </p>
          </div>

          <div class="flex items-center justify-between">
            <div class="flex items-center">
            </div>

            <div class="text-sm">
              <a
                href="#"
                class="font-medium text-primary-600 hover:text-primary-500"
              >
                Forgot your password?
              </a>
            </div>
          </div>

          <div>
            <button
              type="submit"
              class="relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md  group bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
            >
              <span class="absolute inset-y-0 left-0 flex items-center pl-3">
                <LockClosedIcon
                  class="w-5 h-5 text-primary-500 group-hover:text-primary-400"
                  aria-hidden="true"
                />
              </span>
              Sign in
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref } from 'vue';
  import { LockClosedIcon } from '@heroicons/vue/solid';
  import { useRouter, useRoute } from 'vue-router';
  import { useStore } from 'vuex';

  export default {
    components: {
      LockClosedIcon
    },
    setup() {
      const router = useRouter();
      const route = useRoute();
      const store = useStore();

      let email = ref('');
      let password = ref('');
      let formIsValid = true;
      let isLoading = false;
      let mode = 'login';
      let error = null;

      async function submitForm() {
        formIsValid = true;
        if (email.value === '' || password.value.length < 6) {
          formIsValid = false;
          return;
        }

        isLoading = true;

        const actionPayload = {
          email: email.value,
          password: password.value
        };
        try {
          if (mode === 'login') {
            await store.dispatch('login', actionPayload);
          } else {
            await store.dispatch('signup', actionPayload);
          }
          const redirectTo = '/' + (route.query.redirect || '');
          router.replace(redirectTo);
        } catch (err) {
          error = err.message || 'Failed to authenticate.';
        }
        isLoading = false;
      }

      function switchAuthMode() {
        if (mode === 'login') {
          mode = 'signup';
        } else {
          mode = 'login';
        }
      }
      function handleError() {
        error = null;
      }

      return {
        submitForm,
        switchAuthMode,
        handleError,
        email,
        password,
        error,
        isLoading,
        mode,
        formIsValid
      };
    }
  };
</script>