import variables from "../../variables.js";

export default {
  async search(context, payload) {
    let url =
      variables.backendURL + `/docs?page=${payload.page}&size=${payload.size}`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + context.rootGetters.token,
      },
      body: JSON.stringify(payload.search),
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(response.message || "Failed to authenticate.");
      throw error;
    }

    context.commit("setLastSearchObject", payload.search);
    context.commit("setDocs", responseData.data);
    context.commit("setTotalDocs", responseData.total);
    context.commit("setPage", payload.page);
    context.commit("setSize", payload.size);

    // console.log(responseData);
  },
  async byId(context, payload) {
    let url = variables.backendURL + `/docs/${payload}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + context.rootGetters.token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(response.message || "Failed to authenticate.");
      throw error;
    }
    // console.log(responseData);

    context.commit("setActiveDoc", responseData.data);
  },
  async setReminderById(context, payload) {
    let url = variables.backendURL + `/docs/setReminder/${payload.id}`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + context.rootGetters.token,
      },
      body: JSON.stringify({ date: payload.date }),
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(response.message || "Failed to authenticate.");
      throw error;
    }
    console.log(responseData);
  },
  async setViewType(context, payload) {
    context.commit("setViewType", payload);
  },
  async setSortType(context, payload) {
    context.commit("setSortType", payload);
  },
  async setField(context, payload) {
    let url = variables.backendURL + `/docs/${payload._id}`;

    const response = await fetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + context.rootGetters.token,
      },
      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(response.message || "Failed to authenticate.");
      context.dispatch(
        "notification/add",
        {
          heading: "Error!",
          text: response.message,
          duration: 10000,
        },
        { root: true }
      );
      throw error;
    }

    context.dispatch(
      "notification/add",
      {
        heading: "Successfully saved!",
        text: `Field saved in database.`,
        duration: 5000,
      },
      { root: true }
    );
    context.commit("setActiveDoc", responseData.data);
  },
};
