{
	"AND": "AND",
	"NOT": "NOT",
	"OR": "OR",
	"access_groups": "Access Groups",
	"created": {
		"date": "Created date"
	},
	"dashboard": {
		"card": {
			"bookmarks": "Bookmarks",
			"document": "document | documents",
			"last_updated": "Last updated",
			"reminder": "Upcoming reminders",
			"view_documents": "View documents"
		}
	},
	"doc": {
		"comment": "Comment",
		"created": "Created",
		"document_types": "Document Types",
		"email_from": "Email From",
		"email_subject": "Email Subject",
		"email_to": "Email to",
		"frequent_words": "Frequent words",
		"keywords": "Keywords",
		"reminder": "Reminder",
		"status": "Status"
	},
	"doc_details": {
		"document_details": "Document Details",
		"favourite": "Favorite"
	},
	"document_types": "Document Type",
	"email_from": "E-Mail From",
	"email_subject": "E-Mail Subject",
	"email_to": "E-Mail To",
	"frequent_words": {
		"text": "Frequent words"
	},
	"fulltext": "Fulltext",
	"header": {
		"login": "Login",
		"logout": "Logout",
		"user_management": "User Management"
	},
	"keywords": {
		"text": "Keywords"
	},
	"language": "Language",
	"originator": "Originator",
	"paginator": {
		"of": "of",
		"next": "Next",
		"previous": "Previous",
		"results": "results",
		"showing": "Showing",
		"to": "to"
	},
	"reminder": "Reminder",
	"searchbar": {
		"add_filter": "Add filter",
		"delete": "Delete",
		"operator": "Operator",
		"refine_search": "Filter search",
		"search": "Search",
		"search_fields": "Search fields",
		"search_value": "Search value",
		"week": "week | weeks"
	},
	"status": "Status",
	"subtitle": "Subtitle",
	"title": "Title",
	"updated": {
		"date": "Update Date"
	},
	"user": {
		"active": "Active",
		"department": "Department",
		"email": "Email",
		"groups": "Groups",
		"language": "Language",
		"name": "Name",
		"salutation": "Salutation",
		"surname": "Surname",
		"username": "Username",
    "address": {
      "city": "City",
      "street": "Street",
      "zip": "ZIP Code",
      "country": "Country"
    }
	},
	"userlist": {
		"tabs": {
			"details": "Details",
			"documents": "Documents",
			"favorites": "Favorites"
		},
		"total_users": "Total Users",
    "profile": "Profile",
    "address": "Address"
	}
}