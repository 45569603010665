<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <footer class="bg-white">
    <div
      class="
        px-4
        py-6
        mx-auto
        max-w-7xl
        sm:px-6
        md:flex
        md:items-center
        md:justify-between
        lg:px-8
      "
    >
      <a
        href="https://mira-glomas.net"
        target="_blank"
        class="text-secondary-400 hover:text-secondary-500"
      >
        &copy; 2021 MIRA Consulting GmbH
      </a>
    </div>
  </footer>
</template>

<script>
import { MenuIcon } from "@heroicons/vue/outline";
export default {
  setup() {
    return { MenuIcon };
  },
};
</script>