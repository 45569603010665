<template>
  <div class="" v-if="!!totalDocs">
    <TransitionRoot as="template" :show="open">
      <Dialog
        as="div"
        static
        class="fixed inset-0 overflow-hidden"
        @close="open = false"
        :open="open"
      >
        <div class="absolute inset-0 overflow-hidden">
          <TransitionChild
            as="template"
            enter="ease-in-out duration-500"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in-out duration-500"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay
              class="absolute inset-0 transition-opacity bg-opacity-75  bg-secondary-500"
            />
          </TransitionChild>

          <div class="fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <doc-detail
                @closed="closeDetails"
                :docId="activeDocId"
              ></doc-detail>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>

    <div class="mx-auto">
      <div class="bg-white shadow sm:rounded-t-md">

        <ul class="divide-y divide-secondary-200" v-if="viewType === 'list'">
          <li v-for="doc in docs" :key="doc._id">
            <a href="#" class="block hover:bg-secondary-50">
              <div class="flex items-center px-4 py-4 sm:px-4">
                <div
                  class="flex items-center flex-1 min-w-0"
                  @click.prevent="openDetails(doc._id)"
                >
                  <div class="flex-shrink-0 text-secondary-600">
                    <MailIcon
                      class="w-8 h-8"
                      aria-hidden="true"
                      v-if="hasField(doc.email_subject)"
                    />
                    <img
                      :src="doc.cover"
                      alt=""
                      class="w-16 h-20 pointer-events-none"
                    />
                  </div>
                  <div class="flex-1 min-w-0 px-4">
                    <div class="truncate">
                      <div class="flex text-sm">
                        <p class="font-medium truncate text-secondary-600">
                          <span
                            v-if="
                              checkCollection(doc._collection, 'cad') &&
                              hasField(doc.OFILE[0].o)
                            "
                          >
                            {{ doc.OFILE[0].o }}
                          </span>
                          <span v-else-if="hasField(doc.TITD) && hasField(doc.DNUM)">{{ doc.DNUM }}: {{ doc.TITD }}</span>
                          <span v-else>{{ doc.title }}</span>
                          <span v-if="hasField(doc.drawing_number)" class="ml-1"
                            >| Drawing number {{ doc.drawing_number }}</span
                          >
                        </p>
                        <p
                          class="flex-shrink-0 ml-1 font-normal  text-secondary-500"
                        >
                          <span
                            class="ml-1"
                            v-if="
                              hasField(doc.created) &&
                              hasField(doc.created.date)
                            "
                          >
                            <span>{{ t("created.date") }}:</span>
                            <time :datetime="doc.created.date" class="ml-1">{{
                              moment(doc.created.date).format("DD.MM.yyyy")
                            }}</time>
                          </span>
                        </p>
                      </div>
                      <div class="mt-2">
                        <div
                          v-if="!checkCollection(doc._collection, 'norm')"
                          class="flex items-center text-sm text-secondary-500"
                        >
                          <span
                            v-for="dt in doc.document_types"
                            :key="dt"
                            class="
                              inline-flex
                              items-center
                              px-2.5
                              py-0.5
                              rounded-full
                              text-xs
                              font-medium
                              border border-secondary-300
                              text-secondary-500
                              mr-1
                            "
                          >
                            {{ dt }}
                          </span>

                          <span
                            v-if="hasField(doc.reminder)"
                            class="
                              inline-flex
                              items-center
                              px-2.5
                              py-0.5
                              md:ml-2
                              rounded-full
                              text-xs
                              font-medium
                              border border-primary-200
                              text-primary-600
                              bg-primary-50
                            "
                          >
                            <BellIcon class="w-4 h-4 mr-1" aria-hidden="true" />
                            {{ moment(doc.reminder).format("DD.MM.yyyy") }}
                          </span>
                        </div>

                        <div
                          v-else
                        >
                          <p class="text-sm text-gray-500 truncate">
                            {{ doc.REFD }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <button
                    type="button"
                    @click.prevent="downloadFile(doc.PDF[0].e)"
                    class="inline-flex items-center justify-center px-4 py-2 mr-3 text-sm font-medium border rounded-md shadow-sm  text-secondary-700 border-secondary-200 bg-secondary-50 hover:bg-secondary-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500"
                  >
                    <DownloadIcon
                      class="w-5 h-5 mr-3 -ml-1"
                      aria-hidden="true"
                    />
                    Download
                    <span
                      class="text-sm font-medium text-secondary-500"
                      v-if="!!doc.PDF && !!doc.PDF[0].g"
                    >
                      &nbsp;({{ (doc.PDF[0].g / 1024 / 1024).toFixed(2) }} MB)
                    </span>
                  </button>
                </div>
                <div @click.prevent="openDetails(doc._id)">
                  <ChevronRightIcon
                    class="w-5 h-5 text-secondary-400"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </a>
          </li>
        </ul>

        <div v-else-if="viewType === 'grid'">
          <ul
            role="list"
            class="grid grid-cols-2 p-4  gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8"
          >
            <li v-for="doc in docs" :key="doc._id" class="relative">
              <div
                class="block w-full bg-gray-100 rounded-lg shadow  group aspect-w-10 aspect-h-14 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-primary-500"
              >
                <img
                  :src="doc.cover"
                  alt=""
                  class="object-cover pointer-events-none  group-hover:opacity-75"
                />
                <button
                  type="button"
                  class="absolute inset-0 focus:outline-none"
                  @click.prevent="openDetails(doc._id)"
                >
                  <span class="sr-only"
                    >View details for {{ doc.OFILE[0].o }}</span
                  >
                </button>
              </div>
              <p
                class="block mt-2 text-sm font-medium text-gray-900 truncate pointer-events-none "
              >
                {{ doc.OFILE[0].o }}
              </p>
              <p
                class="block text-sm font-medium text-gray-500 pointer-events-none "
              ></p>
              <button
                type="button"
                @click.prevent="downloadFile(doc.PDF[0].e)"
                class="inline-flex items-center justify-center px-4 py-2 mb-4 mr-3 text-sm font-medium border rounded-md shadow-sm  text-secondary-700 border-secondary-200 bg-secondary-50 hover:bg-secondary-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500"
              >
                <DownloadIcon class="w-5 h-5 mr-3 -ml-1" aria-hidden="true" />
                Download
                <span
                  class="text-sm font-medium text-secondary-500"
                  v-if="!!doc.PDF && !!doc.PDF[0].g"
                >
                  &nbsp;({{ (doc.PDF[0].g / 1024 / 1024).toFixed(2) }} MB)
                </span>
              </button>
            </li>
          </ul>
        </div>

        <div v-else>
          <div class="pb-2 border-b border-gray-200">
            <div class="flex justify-center mt-2">
              <p class="text-sm text-gray-500">
                {{ paginatorFrom + activeCoverIndex }} / {{ paginatorTo }}
              </p>
            </div>
          </div>
          <div class="flex flex-row justify-between">
            <button
              type="button"
              @click.prevent="setActiveCoverIndex(activeCoverIndex - 1)"
              class="
                ml-0.5
                p-1.5
                rounded-md
                focus:outline-none
                focus:ring-2 focus:ring-inset focus:ring-primary-500
              "
              :class="[
                viewType === 'grid'
                  ? 'bg-primary-600 shadow-sm text-white'
                  : 'hover:bg-white hover:shadow-sm text-gray-400',
              ]"
            >
              <ChevronLeftIcon class="w-20 h-20" aria-hidden="true" />
              <span class="sr-only">Use grid view</span>
            </button>

            <div class="flex-grow max-w-max">
              <transition
                v-for="(doc, index) in docs"
                :key="index"
                class="space-x-20"
                enter-active-class="transition ease-in duration-250"
                :enter-from-class="
                  activeCoverIndexPrevious < activeCoverIndex
                    ? 'translate-x-full transform opacity-0'
                    : '-translate-x-full transform opacity-0'
                "
                enter-to-class="transform translate-x-0 opacity-100"
              >
                <div v-if="index === activeCoverIndex">
                  <div v-if="!!doc.cover" class="flex flex-col">
                    <img
                      :src="doc.cover"
                      alt=""
                      @click.prevent="openDetails(doc._id)"
                      class="relative cursor-pointer  group-hover:opacity-75 w-96"
                    />
                    <button
                      type="button"
                      @click.prevent="downloadFile(doc.PDF[0].e)"
                      class="inline-flex items-center justify-center px-4 py-2 mb-4 mr-3 text-sm font-medium border rounded-md shadow-sm  text-secondary-700 border-secondary-200 bg-secondary-50 hover:bg-secondary-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500"
                    >
                      <DownloadIcon
                        class="w-5 h-5 mr-3 -ml-1"
                        aria-hidden="true"
                      />
                      Download
                      <span
                        class="text-sm font-medium text-secondary-500"
                        v-if="!!doc.PDF && !!doc.PDF[0].g"
                      >
                        &nbsp;({{ (doc.PDF[0].g / 1024 / 1024).toFixed(2) }} MB)
                      </span>
                    </button>
                  </div>
                  <div v-else>
                    <div class="flex items-center justify-center">
                      <div
                        class="w-32 h-32 ease-linear border-8 border-t-8 border-gray-200 rounded-full  loader"
                      ></div>
                    </div>
                  </div>
                </div>
              </transition>
            </div>

            <button
              type="button"
              @click.prevent="setActiveCoverIndex(activeCoverIndex + 1)"
              class="
                ml-0.5
                p-1.5
                rounded-md
                focus:outline-none
                focus:ring-2 focus:ring-inset focus:ring-primary-500
              "
              :class="[
                viewType === 'grid'
                  ? 'bg-primary-600 shadow-sm text-white'
                  : 'hover:bg-white hover:shadow-sm text-gray-400',
              ]"
            >
              <ChevronRightIcon class="w-20 h-20" aria-hidden="true" />
              <span class="sr-only">Use grid view</span>
            </button>
          </div>
        </div>
      </div>

      <div v-if="!!totalDocs">
        <nav
          class="flex items-center justify-between px-6 py-3 bg-white border-t  border-secondary-200 sm:rounded-b-md"
          aria-label="Pagination"
        >
          <div class="hidden sm:block">
            <p class="text-sm text-secondary-600">
              {{ t("paginator.showing") }}
              {{ " " }}
              <span class="font-medium">{{ paginatorFrom }}</span>
              {{ " " }}
              {{ t("paginator.to") }}
              {{ " " }}
              <span class="font-medium">{{ paginatorTo }}</span>
              {{ " " }}
              {{ t("paginator.of") }}
              {{ " " }}
              <span class="font-medium">{{ totalDocs }}</span>
              {{ " " }}
              {{ t("paginator.results") }}
            </p>
          </div>
          <div class="flex justify-between flex-1 sm:justify-end">
            <button
              :disabled="!showPaginatorFrom"
              @click.prevent="previousPage"
              class="relative inline-flex items-center px-4 py-2 text-sm font-medium bg-white border rounded-md  border-secondary-300 text-secondary-600 hover:bg-secondary-50"
              :class="{
                'disabled:opacity-50 cursor-not-allowed': !showPaginatorFrom,
              }"
            >
              {{ t("paginator.previous") }}
            </button>
            <button
              :disabled="!showPaginatorTo"
              @click.prevent="nextPage"
              class="relative inline-flex items-center px-4 py-2 ml-3 text-sm font-medium bg-white border rounded-md  border-secondary-300 text-secondary-600 hover:bg-secondary-50"
              :class="{
                'disabled:opacity-50 cursor-not-allowed': !showPaginatorTo,
              }"
            >
              {{ t("paginator.next") }}
            </button>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import moment from "moment";
import { useI18n } from "vue-i18n";

import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  BellIcon,
} from "@heroicons/vue/solid";
import {
  MailIcon,
  DownloadIcon,
} from "@heroicons/vue/outline";

import DocDetail from "../../components/doc/DocDetail.vue";
import globals from "../../store/variables.js";

export default {
  components: {
    ChevronRightIcon,
    BellIcon,
    Dialog,
    DialogOverlay,
    DocDetail,
    MailIcon,
    TransitionChild,
    TransitionRoot,
    ChevronLeftIcon,
    DownloadIcon,
  },
  setup() {
    const store = useStore();
    const activeDocId = ref(null);
    const activeCoverIndex = ref(0);
    const activeCoverIndexPrevious = ref(0);

    const { t } = useI18n();

    const hasField = (field) => {
      return !!field;
    };

    const checkCollection = (collection, collectionToMatch) => {
      return !!(collection === collectionToMatch);
    };

    const docs = computed(function () {
      const tempDocs = store.getters["doc/docs"];
      return tempDocs.map((doc) => {
        if (typeof doc.created === "undefined") {
          doc.created = {
            _id: null,
            date: null,
          };
        }
        return doc;
      });
    });

    const totalDocs = computed(function () {
      return store.getters["doc/totalDocs"];
    });

    const lastSearchObject = computed(function () {
      return store.getters["doc/lastSearchObject"];
    });

    const viewType = computed(function () {
      return store.getters["doc/viewType"];
    });

    watch(docs, async function () {
      // activeCoverIndex.value = 0;
      // activeCoverIndexPrevious.value = 0;
      if (docs.value.length){      
        docs.value.forEach( async function(doc, i) {
          const response = await fetch(
            globals.coverURL + "/docs/getobj?obj=" + doc.PAGE1[0].e + "&id=" + doc._id,
            {
              method: "GET",
              headers: {
                Authorization: "Bearer " + store.getters.token,
              },
            }
          );
          let blob = await response.blob();
          docs.value[i].cover = URL.createObjectURL(blob);
        });
      }
    });

    const size = computed(function () {
      return store.getters["doc/size"];
    });

    const page = computed(function () {
      return store.getters["doc/page"];
    });

    const paginatorFrom = computed(function () {
      return (page.value - 1) * size.value === 0
        ? 1
        : (page.value - 1) * size.value + 1;
    });

    const showPaginatorFrom = computed(function () {
      return page.value > 1;
    });

    const paginatorTo = computed(function () {
      const orig = page.value * size.value;
      if (orig > totalDocs.value) {
        return totalDocs.value;
      }
      return orig;
    });

    const showPaginatorTo = computed(function () {
      return paginatorTo.value < totalDocs.value;
    });

    function previousPage() {
      const lastSearchObject = store.getters["doc/lastSearchObject"];
      store.dispatch("doc/search", {
        search: lastSearchObject,
        page: page.value - 1,
        size: size.value,
      });
    }

    function nextPage() {
      const lastSearchObject = store.getters["doc/lastSearchObject"];
      store.dispatch("doc/search", {
        search: lastSearchObject,
        page: page.value + 1,
        size: size.value,
      });
    }

    const open = ref(false);
    function openDetails(id) {
      activeDocId.value = id;
      open.value = true;
    }
    function closeDetails() {
      activeDocId.value = null;
      open.value = false;
    }

    function setActiveCoverIndex(index) {
      if (index >= 0 && index < docs.value.length) {
        activeCoverIndexPrevious.value = activeCoverIndex.value;
        activeCoverIndex.value = index;
      } else if (index == docs.value.length) {
        nextPage();
        activeCoverIndex.value = 0;
        activeCoverIndexPrevious.value = 0;
      } else if (index === -1 && page.value > 1) {
        previousPage();
        activeCoverIndex.value = size.value - 1;
        activeCoverIndexPrevious.value = size.value;
      }
    }

    window.addEventListener("keydown", function (e) {
      if (e.key === "ArrowLeft") {
        setActiveCoverIndex(activeCoverIndex.value - 1);
      } else if (e.key === "ArrowRight") {
        setActiveCoverIndex(activeCoverIndex.value + 1);
      }
    });

    async function downloadFile(id) {
      const response = await fetch(
        globals.coverURL + "/docs/getobj?obj=" + id,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + store.getters.token,
          },
        }
      );
      let blob = await response.blob();
      window.open(URL.createObjectURL(blob));
    }

    return {
      t,
      docs,
      totalDocs,
      lastSearchObject,
      paginatorFrom,
      showPaginatorFrom,
      paginatorTo,
      showPaginatorTo,
      previousPage,
      nextPage,
      open,
      openDetails,
      closeDetails,
      activeDocId,
      page,
      moment,
      hasField,
      checkCollection,
      viewType,
      activeCoverIndex,
      setActiveCoverIndex,
      activeCoverIndexPrevious,
      downloadFile,
    };
  },
};
</script>


<style scoped>
.loader {
  border-top-color: green;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>