<template>
  <div class="mt-8">
    <div class="grid grid-cols-1 gap-5 mt-6 sm:grid-cols-2 lg:grid-cols-3">
      <!-- Card -->
      <div
        v-for="card in cards"
        :key="card.name"
        class="overflow-hidden bg-white rounded-lg shadow"
      >
        <div class="p-5">
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <component
                :is="card.icon"
                class="w-10 h-10 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <div class="flex-1 w-0 ml-5">
              <dl>
                <dt class="text-sm font-medium text-gray-500 truncate">
                  {{ t(card.name) }}
                </dt>
                <dd>
                  <div class="text-lg font-medium text-gray-900">
                    {{ card.count }}
                    {{ t("dashboard.card.document", card.count) }}
                  </div>
                </dd>
              </dl>
            </div>
          </div>
        </div>
        <div class="px-5 py-3 bg-gray-50">
          <div class="text-sm">
            <button
              @click="search(card.search)"
              class="font-medium text-cyan-700 hover:text-cyan-900"
            >
              {{ t("dashboard.card.view_documents") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import {
  DocumentTextIcon,
  CalendarIcon,
  BookmarkIcon,
  TrendingUpIcon,
  ClockIcon,
} from "@heroicons/vue/outline";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const currentDate = new Date();

export default {
  components: {
    DocumentTextIcon,
    CalendarIcon,
    BookmarkIcon,
    TrendingUpIcon,
    ClockIcon,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const { t } = useI18n();

    let cards = ref([
      {
        name: "dashboard.card.reminder",
        href: "#",
        icon: CalendarIcon,
        count: 5,
        search: {
          collections: {
            docs: true,
            emails: true,
            cad: true,
          },
          reminder: new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate() + 14
          ),
          fields: [
            "_id",
            "title",
            "created",
            "email_subject",
            "drawing_number",
            "document_types",
            "OFILE",
            "PAGE1",
            "reminder",
            "_collection",
          ],
        },
      },
      {
        name: "dashboard.card.last_updated",
        href: "#",
        icon: ClockIcon,
        count: 20,
      },
    ]);

    // updateCards();
    const favorites = computed(function () {
      const favs = store.getters.userFavorites;
      console.log(favs);
      return favs;
    });

    // function updateCards() {
    //   console.log("Updating cards");
    //   cards.value.push({
    //     name: "dashboard.card.bookmarks",
    //     href: "#",
    //     icon: BookmarkIcon,
    //     count: favorites.value.length,
    //     search: {
    //       collections: {
    //         docs: true,
    //         emails: true,
    //         cad: true,
    //       },
    //       // filters: favorites.value.map((fav) => {
    //       //   return {
    //       //     bool_field: "_id",
    //       //     bool_value: fav,
    //       //     bool_operator: "OR",
    //       //   };
    //       // }),
    //       fields: [
    //         "_id",
    //         "title",
    //         "created",
    //         "email_subject",
    //         "drawing_number",
    //         "document_types",
    //         "OFILE",
    //         "PAGE1",
    //         "reminder",
    //         "_collection",
    //       ],
    //     },
    //   });
    // }

    function search(searchObject) {
      console.log(searchObject);
      store.dispatch("doc/search", {
        search: searchObject,
        page: 1,
        size: 20,
      });
      router.push("/docs");
    }
    return {
      favorites,
      t,
      cards,
      search,
    };
  },
};
</script>