{
	"AND": "UND",
	"NOT": " NICHT",
	"OR": "ODER",
	"access_groups": "Zugriffsgruppen",
	"created": {
		"date": "Erstelldatum"
	},
	"dashboard": {
		"card": {
			"bookmarks": "Lesezeichen",
			"document": "Dokument | Dokumente",
			"last_updated": "Letzte Aktualisierungen",
			"reminder": "Bevorstehende Erinnerungen",
			"view_documents": "Dokumente anzeigen"
		}
	},
	"doc": {
		"comment": "Kommentar",
		"created": "Erstellt",
		"document_types": "Dokumenttypen",
		"email_from": "E-Mail von",
		"email_subject": "E-Mail Betreff",
		"email_to": "E-Mail an",
		"frequent_words": "Häufige Wörter",
		"keywords": "Stichwörter",
		"reminder": "Erinnerung",
		"status": "Status",
    "DNUM": "Dokumentnummer",
    "AKTU": "Aktualitsierung",
    "HAKTU": "Aktualisierungshistorie",
    "TITD": "Titel deutsch",
    "TITE": "Titel englisch",
    "REFD": "Zusammenfassung deutsch",
    "REFE": "Zusammenfassung englisch"
	},
	"doc_details": {
		"document_details": "Dokumentdetails",
		"favourite": "Favorit"
	},
	"document_types": "Dokumenttyp",
	"email_from": "E-Mail von",
	"email_subject": "E-Mail Betreff",
	"email_to": "E-Mail an",
	"frequent_words": {
		"text": "Häufige Wörter"
	},
	"fulltext": "Volltext",
	"header": {
		"login": "Anmeldung",
		"logout": "Ausloggen",
		"user_management": "Benutzerverwaltung"
	},
	"keywords": {
		"text": "Stichwörter"
	},
	"language": "Sprache",
	"originator": "Urheber",
	"paginator": {
		"of": "von",
		"next": "Vor",
		"previous": "Zurück",
		"results": "Ergebnissen",
		"showing": "Zeige",
		"to": "bis"
	},
	"reminder": "Erinnerung",
	"searchbar": {
		"add_filter": "Filter hinzufügen",
		"delete": "Löschen",
		"operator": "Operator",
		"refine_search": "Suche filtern",
		"search": "Suche",
		"search_fields": "Suchfelder",
		"search_value": "Suchwert",
		"week": "Woche | Wochen"
	},
	"status": "Status",
	"subtitle": "Untertitel",
	"title": "Titel",
	"updated": {
		"date": "Aktualisierungsdatum"
	},
	"user": {
		"active": "Aktiv",
		"department": "Abteilung",
		"email": "E-Mail",
		"groups": "Gruppen",
		"language": "Sprache",
		"name": "Vorname",
		"salutation": "Anrede",
		"surname": "Nachname",
		"username": "Benutzername",
    "address": {
      "city": "Stadt",
      "street": "Straße",
      "zip": "Postleitzahl",
      "country": "Land"
    }
	},
	"userlist": {
		"tabs": {
			"details": "Details",
			"documents": "Dokumente",
			"favorites": "Favoriten"
		},
		"total_users": "Benutzer gesamt",
    "profile": "Profil",
    "address": "Adresse"
	}
}