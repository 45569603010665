<template>
  <div
    class="
      flex
      h-screen
      overflow-hidden
      bg-white
      border border-solid
      rounded-xl
      border-secondary-300
    "
  >
    <!-- Static sidebar for desktop -->
    <div class="flex flex-col flex-1 min-w-0 overflow-hidden">
      <div class="relative z-0 flex flex-1 overflow-hidden">
        <main
          class="
            relative
            z-0
            flex-1
            overflow-y-auto
            focus:outline-none
            xl:order-last
          "
        >
          <article v-if="!!activeUser">
            <!-- Profile header -->
            <div>
              <div class="max-w-5xl px-4 mx-auto sm:px-6 lg:px-8">
                <div class="sm:flex sm:items-end sm:space-x-5">
                  <div
                    class="
                      mt-8
                      sm:flex-1
                      sm:min-w-0
                      sm:flex
                      sm:items-center
                      sm:justify-end
                      sm:space-x-6
                      sm:pb-1
                    "
                  >
                    <div class="flex-1 min-w-0 mt-6 sm:hidden 2xl:block">
                      <h1
                        class="text-2xl font-bold truncate text-secondary-900"
                      >
                        {{ activeUser.name }} {{ activeUser.surname }}
                      </h1>
                    </div>
                    <div
                      class="
                        flex flex-col
                        mt-6
                        space-y-3
                        justify-stretch
                        sm:flex-row
                        sm:space-y-0 sm:space-x-4
                      "
                    ></div>
                  </div>
                </div>
                <div class="flex-1 hidden min-w-0 mt-6 sm:block 2xl:hidden">
                  <h1 class="text-2xl font-bold truncate text-secondary-900">
                    {{ activeUser.name }} {{ activeUser.surname }}
                  </h1>
                </div>
              </div>
            </div>

            <!-- Tabs -->
            <div class="mt-6 sm:mt-2 2xl:mt-5">
              <div class="border-b border-secondary-200">
                <div class="max-w-5xl px-4 mx-auto sm:px-6 lg:px-8">
                  <nav class="flex -mb-px space-x-8" aria-label="Tabs">
                    <a
                      v-for="tab in tabs"
                      :key="tab.name"
                      :href="tab.href"
                      :class="[
                        tab.current
                          ? 'border-primary-500 text-secondary-900'
                          : 'border-transparent text-secondary-500 hover:text-secondary-700 hover:border-secondary-300',
                        'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm',
                      ]"
                      :aria-current="tab.current ? 'page' : undefined"
                    >
                      {{ t("userlist.tabs." + tab.name) }}
                    </a>
                  </nav>
                </div>
              </div>
            </div>

            <!-- new stuff -->
            <div
              class="px-4 mx-auto mt-6 sm:px-6 lg:px-8 divide-y divide-gray-200"
            >
              <div class="space-y-1">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  {{ t("userlist.profile") }}
                </h3>
              </div>
              <dl class="divide-y divide-gray-200">
                <field-edit
                  v-for="field in fieldList"
                  :key="activeUser._id + field.name"
                  :field="field"
                  :id="activeUser._id"
                ></field-edit>
              </dl>
            </div>

            <div
              class="
                px-4
                mx-auto
                mt-16
                sm:px-6
                lg:px-8
                divide-y divide-gray-200
              "
            >
              <div class="space-y-4">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  {{ t("userlist.address") }}
                </h3>
              </div>
              <dl class="divide-y divide-gray-200">
                <field-edit
                  v-for="field in addressFieldList"
                  :key="activeUser._id + field.name"
                  :field="field"
                  :id="activeUser._id"
                ></field-edit>
              </dl>
            </div>
          </article>
        </main>

        <aside
          class="
            flex-shrink-0
            hidden
            border-r border-secondary-200
            xl:order-first
            xl:flex xl:flex-col
            w-96
          "
        >
          <!-- Search -->
          <div class="px-6 pt-4 pb-4">
            <p class="mt-1 text-sm text-secondary-600">
              {{ t("userlist.total_users") }}: {{ users.length }}
            </p>
          </div>
          <!-- Directory list -->
          <nav class="flex-1 min-h-0 overflow-y-auto" aria-label="Directory">
            <div v-for="(entry, letter) in directory" :key="entry">
              <div
                class="
                  sticky
                  top-0
                  z-10
                  px-6
                  py-1
                  text-sm
                  font-medium
                  border-t border-b
                  text-secondary-500
                  border-secondary-200
                  bg-secondary-50
                "
              >
                {{ letter }}
              </div>
              <ul class="relative z-0 divide-y divide-secondary-200">
                <li v-for="person in directory[letter]" :key="person._id">
                  <div
                    class="
                      relative
                      flex
                      items-center
                      px-6
                      py-5
                      space-x-3
                      focus-within:ring-2
                      focus-within:ring-inset
                      focus-within:ring-primary-500
                    "
                    :class="[
                      isActiveUser(person._id)
                        ? 'bg-primary-600'
                        : 'hover:bg-secondary-50',
                    ]"
                  >
                    <div class="flex-shrink-0">
                      <UserCircleIcon
                        class="w-7 h-7"
                        :class="[
                          isActiveUser(person._id)
                            ? 'text-white'
                            : 'text-secondary-600',
                        ]"
                        aria-hidden="true"
                      />
                    </div>
                    <div class="flex-1 min-w-0">
                      <a
                        href="#"
                        class="focus:outline-none"
                        @click="setActiveUser(person._id)"
                      >
                        <span class="absolute inset-0" aria-hidden="true" />
                        <p
                          class="text-sm font-medium"
                          :class="[
                            isActiveUser(person._id)
                              ? 'text-white'
                              : 'text-secondary-900',
                          ]"
                        >
                          {{ person.name }} {{ person.surname }}
                        </p>
                        <p
                          class="text-sm truncate"
                          :class="[
                            isActiveUser(person._id)
                              ? 'text-white'
                              : 'text-secondary-500',
                          ]"
                        >
                          {{ person.email }}
                        </p>
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </aside>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { UserCircleIcon } from "@heroicons/vue/solid";
import FieldEdit from "../../components/field/FieldEdit.vue";
import { useI18n } from "vue-i18n";
const tabs = [
  { name: "details", href: "#", current: true },
  { name: "documents", href: "#", current: false },
  { name: "favorites", href: "#", current: false },
];

export default {
  components: {
    UserCircleIcon,
    FieldEdit,
  },
  setup() {
    const sidebarOpen = ref(false);

    const store = useStore();
    let userCount = ref(0);

    const { t } = useI18n();

    store.dispatch("user/fetchUsers");

    let users = computed(function () {
      return store.getters["user/users"];
    });

    let activeUser = computed(function () {
      const au = store.getters["user/activeUser"];
      // console.log(au);
      return au;
    });

    const isActiveUser = (userId) => {
      return !!(userId === activeUser?.value?._id);
    };

    function createFieldList(context) {
      return [
        {
          name: "username",
          content: context.username,
          editable: true,
          database: "user",
          inputType: "text",
        },
        {
          name: "salutation",
          content: context.salutation,
          editable: true,
          database: "user",
          inputType: "text",
        },
        {
          name: "name",
          content: context.name,
          editable: true,
          database: "user",
          inputType: "text",
        },
        {
          name: "surname",
          content: context.surname,
          editable: true,
          database: "user",
          inputType: "text",
        },
        {
          name: "email",
          content: context.email,
          editable: true,
          database: "user",
          inputType: "text",
        },
        {
          name: "language",
          content: context.language,
          editable: true,
          database: "user",
          inputType: "select",
        },
        {
          name: "active",
          content: context.active,
          editable: true,
          database: "user",
          showEmpty: true,
          inputType: "toggle",
        },
        {
          name: "department",
          content: context.department,
          editable: true,
          database: "user",
          inputType: "text",
        },
        {
          name: "groups",
          content: context.groups,
          editable: true,
          database: "user",
          inputType: "pills",
          options: ["Ausbildung", "Einkauf", "GL", "Konstruktion"],
        },
      ];
    }

    function createAddressFieldList(context) {
      return [
        {
          name: "address.street",
          content: context.address.street,
          editable: true,
          database: "user",
          showEmpty: true,
          inputType: "text",
        },
        {
          name: "address.zip",
          content: context.address.zip,
          editable: true,
          database: "user",
          showEmpty: true,
          inputType: "text",
        },
        {
          name: "address.city",
          content: context.address.city,
          editable: true,
          database: "user",
          showEmpty: true,
          inputType: "text",
        },
        {
          name: "address.country",
          content: context.address.country,
          editable: true,
          database: "user",
          showEmpty: true,
          inputType: "text",
        },
      ];
    }

    const fieldList = computed(function () {
      const list = createFieldList(activeUser.value);
      const filteredList = list.filter(
        (item) =>
          item.showEmpty ||
          (!item.showEmpty && !!item.content && item.content.length)
      );
      return filteredList;
    });

    const addressFieldList = computed(function () {
      const list = createAddressFieldList(activeUser.value);
      const filteredList = list.filter(
        (item) =>
          item.showEmpty ||
          (!item.showEmpty && !!item.content && item.content.length)
      );
      return filteredList;
    });

    const directory = computed(() => {
      console.log(users.value);
      const directoryData = {};
      const letters = [
        ...new Set(users.value.map((user) => user.surname.charAt(0))),
      ].sort();

      letters.forEach((letter) => {
        directoryData[letter] = [];
      });

      users.value.forEach((user) => {
        userCount.value = userCount.value + 1;
        directoryData[user.surname.charAt(0)].push(user);
      });

      return directoryData;
    });

    function setActiveUser(id) {
      if (id !== undefined) {
        store.dispatch("user/setActiveUser", id);
      }
    }

    return {
      t,
      tabs,
      sidebarOpen,
      directory,
      userCount,
      users,
      setActiveUser,
      activeUser,
      fieldList,
      addressFieldList,
      isActiveUser,
    };
  },
};
</script>