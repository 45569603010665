<template>
  <div class="flex items-center py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
    <dt class="text-sm font-medium text-gray-500">
      {{ t(field.database + "." + field.name) }}
    </dt>
    <dd
      class="flex items-center mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
    >
      <div class="flex-grow">
        <template v-if="isField('pills')">
          <span
            v-for="(val, index) in fieldContent"
            :key="val"
            class="
              inline-flex
              items-center
              px-2.5
              py-0.5
              mr-2
              mb-2
              rounded-full
              text-sm
              font-medium
              border border-primary-200
              text-primary-600
              bg-primary-50
            "
          >
            {{ val }}
            <XCircleIcon
              v-if="editModeActive"
              @click="removeItemAtIndex(index)"
              class="w-4 h-4 ml-1 -mr-1 cursor-pointer"
              aria-hidden="true"
          /></span>

          <div class="inline-flex">
            <Menu as="div" v-if="editModeActive">
              <MenuButton
                class="
                  inline-flex
                  items-center
                  px-2.5
                  py-0.5
                  mr-2
                  mb-2
                  rounded-full
                  text-sm
                  font-medium
                  border
                  text-primary-50
                  bg-primary-600
                  cursor-pointer
                "
                >+</MenuButton
              >

              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="
                    origin-top-right
                    absolute
                    mt-2
                    w-56
                    rounded-md
                    shadow-lg
                    bg-white
                    ring-1 ring-black ring-opacity-5
                    focus:outline-none
                    cursor-pointer
                  "
                >
                  <MenuItem
                    v-for="val in fieldOptions"
                    :key="val"
                    v-slot="{ active }"
                  >
                    <span
                      :class="[
                        active
                          ? 'bg-primary-100 text-primary-900'
                          : 'text-primary-700',
                        'block px-4 py-2 text-sm',
                      ]"
                      @click="addToFieldContent(val)"
                    >
                      {{ val }}
                    </span>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </template>

        <template v-else-if="isField('toggle')">
          <div class="flex justify-between">
            <template v-if="editModeActive && isEditable">
              <base-toggle v-model="fieldContent"></base-toggle>
            </template>
            <template v-else>
              <span class="mr-1">{{ fieldContent }}</span>
            </template>
          </div>
        </template>

        <template v-else-if="isField('select')">
          <div v-if="editModeActive && isEditable">
            <base-select
              :selectOptions="languages"
              :selectedDefault="fieldContent"
              @changedSelected="changeSelectedField"
            >
            </base-select>
          </div>

          <template v-else>
            <span class="mr-1">{{ fieldContent }}</span>
          </template>
        </template>

        <template v-else-if="isField('textarea')">
          <template v-if="editModeActive && isEditable">
            <textarea
              class="
                block
                w-full
                border
                rounded-md
                shadow-sm
                border-secondary-300
                focus:ring-primary-500
                focus:border-primary-500
                sm:text-sm
              "
              v-model="fieldContent"
            ></textarea>
          </template>
          <template v-else>
            <div class="flex items-center justify-between">
              <span class="mr-1">{{ fieldContent }}</span>
            </div>
          </template>
        </template>

        <template v-else-if="isField('datepicker')">
          <template v-if="editModeActive && isEditable">
            <date-picker v-model="fieldContent" />
          </template>
          <template v-else>
            <div class="flex items-center justify-between">
              <span class="mr-1">{{ toLocaleDate(fieldContent) }}</span>
            </div>
          </template>
        </template>

        <template v-else>
          <template v-if="editModeActive && isEditable">
            <div class="relative flex rounded-md shadow-sm focus-within:z-10">
              <input
                type="text"
                name=""
                id=""
                class="
                  block
                  w-full
                  rounded-md
                  border-secondary-300
                  focus:ring-primary-500
                  focus:border-primary-500
                  sm:text-sm
                "
                v-model="fieldContent"
              />
            </div>
          </template>
          <template v-else>
            <div class="flex items-center justify-between">
              <span class="mr-1" v-html="fieldContent"></span>
            </div>
          </template>
        </template>
      </div>

      <div class="flex ml-5" v-if="isEditable">
        <template v-if="editModeActive">
          <button
            class="
              relative
              px-2
              py-2
              -ml-px
              text-sm
              font-medium
              text-white
              border
              rounded-l-md
              border-danger-700
              bg-danger-600
              hover:bg-danger-700
              focus:outline-none
              focus:ring-1 focus:ring-danger-800
              focus:border-danger-800
            "
            @click="cancelAttributeEdit"
          >
            <XIcon class="w-4 h-4" aria-hidden="true" />
          </button>
          <button
            class="
              relative
              inline-flex
              items-center
              px-2
              py-2
              -ml-px
              text-sm
              font-medium
              text-white
              border border-success-700
              rounded-r-md
              bg-success-600
              hover:bg-success-700
              focus:outline-none
              focus:ring-1 focus:ring-success-800
              focus:border-success-800
            "
            @click="saveAttributeEdit"
          >
            <CheckIcon class="w-4 h-4" aria-hidden="true" />
          </button>
        </template>
        <template v-else>
          <button
            type="button"
            class="
              inline-flex
              items-center
              p-1
              border
              rounded-full
              shadow-sm
              text-secondary-700
              border-secondary-200
              bg-secondary-50
              hover:bg-secondary-200
              focus:outline-none
              focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500
            "
            @click="toggleAttributeEdit"
            v-if="isEditable"
          >
            <PencilIcon class="w-3 h-3" aria-hidden="true" />
          </button>
        </template>
      </div>
    </dd>
  </div>
</template>

<script>
import { ref, unref, computed, watch } from "vue";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import moment from "moment";
import { useStore } from "vuex";
import BaseToggle from "@/components/ui/BaseToggle.vue";
import BaseSelect from "@/components/ui/BaseSelect.vue";
import {
  PencilIcon,
  CheckIcon,
  XIcon,
  XCircleIcon,
} from "@heroicons/vue/solid";
import { DatePicker } from "v-calendar";
import languages from "@/data/languages";
import { useI18n } from "vue-i18n";

export default {
  components: {
    BaseToggle,
    BaseSelect,
    PencilIcon,
    CheckIcon,
    XIcon,
    XCircleIcon,
    DatePicker,
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
  },
  props: ["field", "id"],
  setup(props) {
    const store = useStore();

    const { t } = useI18n();

    let fieldContent = ref(props.field.content);
    let fieldOptions = ref(props.field.options);
    let fieldType = ref(props.field.inputType);
    let fieldContentPrev = unref(fieldContent);
    let editModeActive = ref(false);

    function isField(checkField) {
      return fieldType.value === checkField;
    }

    const isEditable = computed(function () {
      return !!props.field.editable;
    });

    function toggleAttributeEdit() {
      editModeActive.value = !editModeActive.value;
    }

    function cancelAttributeEdit() {
      fieldContent.value = props.field.content;
      toggleAttributeEdit();
    }

    function saveAttributeEdit() {
      // fieldContentPrev = fieldContent.value;
      toggleAttributeEdit();

      store.dispatch(props.field.database + "/setField", {
        _id: props.id,
        field: props.field.name,
        value: fieldContent.value,
      });
    }

    function toLocaleDate(date) {
      return moment(date).format("DD.MM.yyyy");
    }

    function changeSelectedField(payload) {
      fieldContent.value = payload;
    }

    function addToFieldContent(val) {
      if (!fieldContent.value.includes(val)) {
        fieldContent.value.push(val);
      }
    }

    function removeItemAtIndex(index) {
      console.log(fieldContentPrev);
      fieldContent.value.splice(index, 1);
    }

    watch(fieldContent, () => {
      if (fieldType.value === "datepicker") {
        store.dispatch("doc/setReminderById", {
          id: props.docId,
          date: fieldContent.value,
        });

        store.dispatch("notification/add", {
          heading: "Successfully saved!",
          text: "Added reminder on " + toLocaleDate(fieldContent.value),
          duration: 5000,
        });
      }
    });

    return {
      t,
      isField,
      fieldContent,
      fieldOptions,
      editModeActive,
      toggleAttributeEdit,
      saveAttributeEdit,
      cancelAttributeEdit,
      isEditable,
      languages,
      changeSelectedField,
      toLocaleDate,
      addToFieldContent,
      removeItemAtIndex,
    };
  },
};
</script>
