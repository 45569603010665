import variables from "../../variables.js";

export default {
  async fetchUsers(context) {
    let url = variables.backendURL + `/users`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + context.rootGetters.token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(response.message || "Failed to authenticate.");
      throw error;
    }
    context.commit("setUsers", responseData.data);
  },
  async setActiveUser(context, payload) {
    let url = variables.backendURL + `/users/${payload}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + context.rootGetters.token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(response.message || "Failed to authenticate.");
      throw error;
    }

    context.commit("setActiveUser", responseData.data);
  },
  async setField(context, payload) {
    let url = variables.backendURL + `/users/${payload._id}`;

    const response = await fetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + context.rootGetters.token,
      },
      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(response.message || "Failed to authenticate.");
      context.dispatch(
        "notification/add",
        {
          heading: "Error!",
          text: response.message,
          duration: 10000,
        },
        { root: true }
      );
      throw error;
    }
    context.dispatch(
      "notification/add",
      {
        heading: "Successfully saved!",
        text: `Field saved in database.`,
        duration: 5000,
      },
      { root: true }
    );
    context.commit("setActiveUser", responseData.data);
  },
  async addField(context, payload) {
    let url = variables.backendURL + `/users/${payload._id}`;

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + context.rootGetters.token,
      },
      body: JSON.stringify(payload),
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(response.message || "Failed to authenticate.");
      context.dispatch(
        "notification/add",
        {
          heading: "Error!",
          text: response.message,
          duration: 10000,
        },
        { root: true }
      );
      throw error;
    }
    context.dispatch(
      "notification/add",
      {
        heading: "Successfully saved!",
        text: `Field saved in database.`,
        duration: 5000,
      },
      { root: true }
    );
    context.commit("setActiveUser", responseData.data);
  },
};
