<template>
  <div class="flex mb-3">
    <div class="flex-none w-56">
      <base-select
        :selectOptions="searchFields"
        :selectedDefault="selectedSearchField"
        @changedSelected="changeSelectedSearchField"
      >
        <template v-slot:label>{{ t("searchbar.search_fields") }}</template>
      </base-select>
    </div>

    <div class="flex-grow mx-3">
      <label
        for="searchVal"
        class="block text-sm font-medium text-secondary-600"
        >{{ t("searchbar.search_value") }}</label
      >
      <div class="mt-1">
        <input
          type="text"
          name="searchVal"
          id="searchVal"
          v-model.trim="searchValue"
          autocomplete="off"
          class="block w-full rounded-md shadow-sm  focus:ring-primary-500 focus:border-primary-500 sm:text-sm border-secondary-300"
        />
      </div>
    </div>

    <div class="flex-none w-32">
      <base-select
        :selectOptions="operatorOptions"
        :selectedDefault="selectedSearchOperator"
        @changedSelected="changeSelectedSearchOperator"
      >
        <template v-slot:label>{{ t("searchbar.operator") }}</template>
      </base-select>
    </div>

    <div class="ml-3">
      <label class="block text-sm font-medium text-secondary-600">{{
        t("searchbar.delete")
      }}</label>
      <div class="mt-1">
        <button
          @click="removeSearchFilter"
          type="button"
          class="inline-flex items-center p-2 text-sm font-medium leading-4 text-white bg-red-600 border border-transparent rounded-md shadow-sm  hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        >
          <MinusIcon class="w-5 h-5" aria-hidden="true" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import BaseSelect from "@/components/ui/BaseSelect.vue";
import { MinusIcon } from "@heroicons/vue/solid";

import { useI18n } from "vue-i18n";

const operatorOptions = ["AND", "OR", "NOT"];
export default {
  components: {
    BaseSelect,
    MinusIcon,
  },
  props: ["filterObject", "searchFields"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const selectedSearchField = ref(
      props.filterObject.bool_field || props.searchFields[0]
    );
    const searchValue = ref(props.filterObject.bool_value || "");
    const selectedSearchOperator = ref(
      props.filterObject.bool_operator || operatorOptions[0]
    );

    function changeSelectedSearchField(payload) {
      selectedSearchField.value = payload;
      emitFilter();
    }

    function changeSelectedSearchOperator(payload) {
      selectedSearchOperator.value = payload;
      emitFilter();
    }

    function removeSearchFilter() {
      emitFilterToDelete();
    }

    watch(searchValue, function () {
      emitFilter();
    });

    function emitFilter() {
      emit("filterChanged", {
        id: props.filterObject.id,
        bool_field: selectedSearchField.value,
        bool_value: searchValue.value,
        bool_operator: selectedSearchOperator.value,
      });
    }

    function emitFilterToDelete() {
      emit("filterDelete", {
        id: props.filterObject.id,
      });
    }

    return {
      t,
      changeSelectedSearchField,
      selectedSearchField,
      changeSelectedSearchOperator,
      selectedSearchOperator,
      operatorOptions,
      searchValue,
      removeSearchFilter,
    };
  },
};
</script>