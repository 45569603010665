import { createRouter, createWebHistory } from "vue-router";

import NotFound from "./pages/NotFound.vue";
import UserAuth from "./pages/auth/UserAuth.vue";
import TheDashboard from "./pages/dashboard/TheDashboard.vue";
import TheDocList from "./pages/doc/TheDocList.vue";
import TheUserList from "./pages/user/TheUserList.vue";
import store from "./store/index";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      component: TheDashboard,
      meta: {
        requiresAuth: true
      } 
    },
    {
      path: "/auth",
      component: UserAuth,
      meta: {
        requiresUnauth: true
      }
    },
    {
      path: "/:notFound(.*)",
      component: NotFound
    },
    {
      path: "/docs",
      component: TheDocList,
      meta:{
        requiresAuth: true
      }
    },
    {
      path: "/users",
      component: TheUserList,
      meta:{
        requiresAuth: true
      }
    }
  ],
});

router.beforeEach(function (to, _, next) {
  if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
    next("/auth");
  } else if (to.meta.requiresUnauth && store.getters.isAuthenticated) {
    next("/");
  } else {
    next();
  }
});

export default router;
