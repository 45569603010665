<template>
  <div v-if="isLoggedIn">
    <div class="flex-grow w-full mx-auto md:flex">
      <div class="relative flex-1 min-w-0 md:flex">
        <span class="absolute right-0 z-0 inline-flex shadow-sm">
          <a
            v-if="locale !== 'de'"
            href="#"
            @click="switchLocale('de')"
            class="relative inline-flex items-center px-4 py-2 text-sm font-medium bg-white border cursor-pointer  rounded-bl-md border-secondary-300 text-secondary-600 hover:bg-secondary-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary-700 focus:border-primary-700"
            >DE</a
          >
          <a
            v-if="locale !== 'en'"
            href="#"
            @click="switchLocale('en')"
            class="relative inline-flex items-center px-4 py-2 text-sm font-medium bg-white border cursor-pointer  rounded-bl-md border-secondary-300 text-secondary-600 hover:bg-secondary-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary-700 focus:border-primary-700"
            >EN</a
          >
          <router-link
            to="/users"
            class="relative inline-flex items-center px-4 py-2 text-sm font-medium bg-white border cursor-pointer  border-secondary-300 text-secondary-600 hover:bg-secondary-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary-700 focus:border-primary-700"
            >{{ t("header.user_management") }}</router-link
          >
          <router-link
            v-if="!isLoggedIn"
            to="/auth"
            class="relative inline-flex items-center px-4 py-2 text-sm font-medium bg-white border cursor-pointer  rounded-br-md border-secondary-300 text-secondary-600 hover:bg-secondary-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary-700 focus:border-primary-700"
            >{{ t("header.login") }}</router-link
          >
          <a
            v-if="isLoggedIn"
            @click="logout"
            class="relative inline-flex items-center px-4 py-2 text-sm font-medium bg-white border cursor-pointer  rounded-br-md border-secondary-300 text-secondary-600 hover:bg-secondary-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary-700 focus:border-primary-700"
            >{{ t("header.logout") }}</a
          >
        </span>

        <div class="w-48 max-w-full md:pr-4 xl:flex-shrink-0 xl:w-96">
          <div class="overflow-hidden bg-white shadow rounded-b-xl">
            <div class="p-8 px-4 py-5">
              <router-link to="/">
                <img
                  src="@/assets/logo.png"
                  class="w-full h-auto mx-auto"
                  style="max-width: 10rem"
                />
              </router-link>
            </div>
          </div>
        </div>
        <div class="md:pl-4 md:min-w-0 md:flex-1">
          <div class="h-full">
            <div
              class="h-full overflow-hidden bg-white bg-center bg-no-repeat bg-cover shadow  rounded-b-xl bg-header"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const router = useRouter();
    const store = useStore();

    const open = ref(false);

    const { t, locale } = useI18n();

    function logout() {
      store.dispatch("logout");
      router.replace("/auth");
    }

    const isLoggedIn = computed(function () {
      return store.getters.isAuthenticated;
    });

    function switchLocale(loc) {
      locale.value = loc;
    }
    return {
      t,
      locale,
      open,
      logout,
      isLoggedIn,
      switchLocale,
    };
  },
};
</script>