<template>
  <div
    class="flex flex-col justify-between max-h-screen min-h-screen shadow  bg-secondary-100"
  >
    <div class="px-4 sm:px-6 lg:px-8">
      <the-header v-if="isLoggedIn"></the-header>

      <div
        :class="{
          'my-8 mb-min-auto': isLoggedIn,
        }"
      >
        <div class="flex-grow w-full mx-auto lg:flex">
          <!-- Left sidebar & main wrapper -->
          <div class="flex-1 min-w-0 xl:flex">
            <div class="xl:pr-4 xl:flex-shrink-0 xl:w-96" v-if="isLoggedIn">
              <div class="h-full pb-6">
                <!-- Start left column area -->
                <div class="relative h-full" style="min-height: 12rem">
                  <the-searchtree></the-searchtree>
                </div>
                <!-- End left column area -->
              </div>
            </div>

            <div class="xl:pl-4 lg:min-w-0 lg:flex-1">
              <div class="h-full">
                <!-- Start main area-->
                <div class="relative h-full">
                  <div class="mb-5 rounded-lg bg-whiteshadow" v-if="isLoggedIn">
                    <the-searchbar></the-searchbar>
                  </div>

                  <router-view v-slot="slotProps" class="overflow-auto">
                    <transition name="route" mode="out-in">
                      <component :is="slotProps.Component"></component>
                    </transition>
                  </router-view>
                </div>
                <!-- End main area -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <the-footer v-if="isLoggedIn"></the-footer>

    <div
      aria-live="assertive"
      class="fixed inset-0 flex items-end px-4 py-6 pointer-events-none  sm:p-6 sm:items-start"
    >
      <div class="flex flex-col items-center w-full space-y-4 sm:items-end">
        <base-notification
          v-for="notification in notifications"
          :key="notification.id"
          :modelValue="notification"
        ></base-notification>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import TheHeader from "./components/layout/TheHeader.vue";
import TheFooter from "./components/layout/TheFooter.vue";
import TheSearchtree from "./components/layout/TheSearchtree.vue";
import TheSearchbar from "./components/doc/TheSearchbar.vue";
import BaseNotification from "./components/ui/BaseNotification.vue";

export default {
  components: {
    TheHeader,
    TheFooter,
    TheSearchtree,
    TheSearchbar,
    BaseNotification,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const notifications = computed(function () {
      const myNotifications = store.getters["notification/notifications"];
      return myNotifications;
    });

    const isLoggedIn = computed(function () {
      return store.getters.isAuthenticated;
    });

    const didAutoLogout = computed(function () {
      return store.getters.didAutoLogout;
    });

    watch(didAutoLogout, function (curValue, oldValue) {
      if (curValue && curValue !== oldValue) {
        router.replace("/");
      }
    });

    watch(notifications, function (curValue) {
      console.log(curValue);
    });

    store.dispatch("tryLogin");

    return {
      isLoggedIn,
      didAutoLogout,
      notifications,
    };
  },
};
</script>
