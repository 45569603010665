import variables from "../../variables.js";

export default {
  async searchtree(context) {
    let url = variables.backendURL + `/searchtree`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + context.rootGetters.token,
      }
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(response.message || "Failed to authenticate.");
      throw error;
    }
    context.commit("setSearchtree", responseData.data);
    // console.log(responseData);
  },
};
