export default {
  addNotification(state, payload) {
    payload.id = new Date();
    payload.show = true;
    state.notifications.push(payload);

    setTimeout(() => {
      state.notifications = state.notifications.filter(
        (notification) => notification.id !== payload.id
      );
    }, payload.duration);
  },
};
