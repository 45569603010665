export default {
  setLastSearchObject(state, payload) {
    state.lastSearchObject = payload;
  },
  setDocs(state, payload) {
    state.docs = payload;
  },
  setActiveDoc(state, payload) {
    state.activeDoc = payload;
  },
  setTotalDocs(state, payload) {
    state.totalDocs = payload;
  },
  setPage(state, payload) {
    state.page = payload;
  },
  setSize(state, payload) {
    state.size = payload;
  },
  setViewType(state, payload) {
    state.viewType = payload;
  },
  setSortType(state, payload) {
    state.sortType = payload;
  },
};
