<template>
  <div class="lg:min-w-24">
    <ul class="bg-white border border-solid rounded-xl border-secondary-300">
      <tree-item
        v-for="(item, index) in searchtreeData"
        :key="item.name"
        :name="item.name"
        :children="item.children"
        :count="item.count"
        :index="index"
        :isRootLvl="true"
        :parent="item.name"
      ></tree-item>
    </ul>
  </div>
</template>

<script>
  import { computed } from 'vue';
  import { useStore } from 'vuex';

  import TreeItem from '../tree/TreeItem.vue';

  export default {
    components: {
      TreeItem
    },
    setup() {
      const store = useStore();

      store.dispatch('searchtree/searchtree');

      const searchtreeData = computed(function() {
        return store.getters['searchtree/searchtree'];
      });

      return {
        searchtreeData
      };
    }
  };
</script>