import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

import { ArrowNarrowDownIcon } from "@heroicons/vue/solid";

export default {
  namespaced: true,
  state() {
    return {
      docs: [],
      activeDoc: {},
      totalDocs: 0,
      page: null,
      size: null,
      lastSearchObject: null,
      viewType: "list",
      sortType: {
        name: "Created",
        sort: "created.date:desc",
        icon: ArrowNarrowDownIcon,
      },
    };
  },
  mutations,
  actions,
  getters,
};
