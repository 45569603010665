<template>
  <div class="w-screen max-w-2xl">
    <div
      v-if="!!doc"
      class="flex flex-col h-full overflow-y-scroll bg-white shadow-xl"
    >
      <div class="px-4 py-6 sm:px-6">
        <div class="flex items-start justify-between">
          <DialogTitle class="text-lg font-medium text-secondary-900">
            {{ t("doc_details.document_details") }}
          </DialogTitle>
          <div class="flex items-center ml-3 h-7">
            <button
              class="
                bg-white
                rounded-md
                text-secondary-400
                hover:text-secondary-500
                focus:ring-2 focus:ring-primary-500
              "
              @click="closeDetails"
            >
              <span class="sr-only">Close panel</span>
              <XIcon class="w-6 h-6" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
      <!-- Main -->
      <div class="h-full p-8 pt-0 overflow-y-auto bg-white">
        <div class="pb-16 space-y-6">
          <div>
            <div
              class="
                block
                w-full
                overflow-hidden
                rounded-lg
                aspect-w-10 aspect-h-7
              "
            >
              <img :src="image" alt="" class="object-contain" />
            </div>
            <div class="flex items-start justify-between mt-4">
              <div>
                <h2
                  class="
                    flex
                    items-center
                    text-lg
                    font-medium
                    text-secondary-900
                  "
                >
                  <span class="mr-1">
                    <MailIcon
                      class="w-6 h-6"
                      aria-hidden="true"
                      v-if="!!doc.email_subject"
                    />
                    <DocumentTextIcon
                      class="w-6 h-6"
                      aria-hidden="true"
                      v-else
                    />
                  </span>
                  <span class="sr-only">Details for </span>
                  <div v-if="!!doc.title">{{ doc.title }}</div>
                  <div v-else-if="!!doc.drawing_number">
                    {{ doc.drawing_number }}
                  </div>
                </h2>
              </div>
              <button
                type="button"
                @click.prevent="downloadFile(doc.PDF[0].e)"
                class="
                  inline-flex
                  items-center
                  justify-center
                  px-4
                  py-2
                  mr-3
                  text-sm
                  font-medium
                  border
                  rounded-md
                  shadow-sm
                  text-secondary-700
                  border-secondary-200
                  bg-secondary-50
                  hover:bg-secondary-200
                  focus:outline-none
                  focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500
                "
              >
                <DownloadIcon class="w-5 h-5 mr-3 -ml-1" aria-hidden="true" />
                Download
                <span
                  class="text-sm font-medium text-secondary-500"
                  v-if="!!doc.PDF && !!doc.PDF[0].g"
                >
                  &nbsp;({{ (doc.PDF[0].g / 1024 / 1024).toFixed(2) }} MB)
                </span>
              </button>
              <button
                type="button"
                class="
                  flex
                  items-center
                  justify-center
                  w-8
                  h-8
                  ml-4
                  bg-white
                  rounded-full
                  text-secondary-400
                  hover:bg-secondary-100
                  hover:text-secondary-500
                  focus:outline-none
                  focus:ring-2 focus:ring-primary-500
                "
              >
                <BookmarkIcon class="w-6 h-6" aria-hidden="true" />
                <span class="sr-only">Favorite</span>
              </button>
            </div>
            <div class="flex items-start mt-4" v-if="!!doc.access_groups">
              <LockClosedIcon class="w-5 h-5 mr-2 text-red-500" />
              <span
                class="text-sm font-medium text-secondary-600"
                v-html="doc.access_groups.join('; ')"
              ></span>
            </div>
            <div class="flex items-start mt-4" v-else>
              <LockOpenIcon class="w-5 h-5 mr-2 text-green-500" />
            </div>
          </div>
          <div>
            <h3 class="font-medium text-secondary-900">Information</h3>
            <dl
              class="
                mt-2
                border-t border-b
                divide-y
                border-secondary-200
                divide-secondary-200
              "
            >
              <field-edit
                v-for="field in fieldList"
                :key="docId + field.name"
                :field="field"
                :id="docId"
              ></field-edit>
            </dl>
          </div>
          <div v-if="!!doc.fulltext">
            <div class="flex items-center justify-between mt-2">
              <p
                class="text-sm italic text-secondary-500"
                v-html="doc.fulltext.substring(0, 1000) + '...'"
              ></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";

import { DialogTitle } from "@headlessui/vue";
import {
  DocumentTextIcon,
  DownloadIcon,
  BookmarkIcon,
  LockClosedIcon,
  LockOpenIcon,
  MailIcon,
  XIcon,
} from "@heroicons/vue/outline";

import moment from "moment";
import FieldEdit from "../../components/field/FieldEdit.vue";
import globals from "../../store/variables.js";
import { useI18n } from "vue-i18n";

export default {
  components: {
    MailIcon,
    DialogTitle,
    DocumentTextIcon,
    DownloadIcon,
    BookmarkIcon,
    LockClosedIcon,
    LockOpenIcon,
    XIcon,
    FieldEdit,
  },
  emits: ["closed"],
  props: ["docId"],
  setup(props, { emit }) {
    const store = useStore();
    const image = ref("");

    const { t } = useI18n();

    if (props.docId) {
      store.dispatch("doc/byId", props.docId);
    }

    const doc = computed(function () {
      const myDoc = store.getters["doc/activeDoc"];
      console.log("myDoc", myDoc);

      return myDoc;
    });

    function closeDetails() {
      emit("closed", true);
    }

    function toLocaleString(date) {
      return moment(date).format("DD.MM.yyyy, h:mm:ss");
    }

    function toLocaleDate(date) {
      return moment(date).format("DD.MM.yyyy");
    }

    function createFieldList(context) {
      return [
        {
          name: "created",
          content: context?.created?.date
            ? toLocaleString(context.created.date)
            : null,
          editable: false,
          database: "doc",
          inputType: "text",
          showEmpty: false,
        },
        {
          name: "reminder",
          content: context.reminder,
          editable: true,
          database: "doc",
          inputType: "datepicker",
          showEmpty: true,
        },
        {
          name: "comment",
          content: context.comment,
          editable: true,
          database: "doc",
          inputType: "textarea",
          showEmpty: true,
        },
        {
          name: "email_from",
          content: context.email_from,
          editable: false,
          database: "doc",
          inputType: "text",
          showEmpty: false,
        },
        {
          name: "email_to",
          content: context.email_to,
          editable: false,
          database: "doc",
          inputType: "text",
          showEmpty: false,
        },
        {
          name: "email_subject",
          content: context.email_subject,
          editable: false,
          database: "doc",
          inputType: "textarea",
          showEmpty: false,
        },
        {
          name: "status",
          content: context.status,
          editable: false,
          database: "doc",
          inputType: "toggle",
          showEmpty: false,
        },
        {
          name: "document_types",
          content: context.document_types,
          editable: false,
          database: "doc",
          inputType: "pills",
          showEmpty: false,
        },
        {
          name: "keywords",
          content: context?.keywords?.length
            ? context.keywords.map((a) => a.text)
            : null,
          editable: false,
          database: "doc",
          inputType: "pills",
          showEmpty: false,
        },
        {
          name: "frequent_words",
          content: context?.frequent_words?.length
            ? context.frequent_words.map((a) => a.text)
            : null,
          editable: false,
          database: "doc",
          inputType: "pills",
          showEmpty: false,
        },
        {
          name: "DNUM",
          content: context.DNUM,
          editable: false,
          database: "doc",
          inputType: "text",
          showEmpty: false,
        },
        {
          name: "AKTU",
          content: context.AKTU,
          editable: false,
          database: "doc",
          inputType: "text",
          showEmpty: false,
        },
        {
          name: "HAKTU",
          content: context.HAKTU,
          editable: false,
          database: "doc",
          inputType: "text",
          showEmpty: false,
        },
        {
          name: "TITD",
          content: context.TITD,
          editable: false,
          database: "doc",
          inputType: "text",
          showEmpty: false,
        },
        {
          name: "TITE",
          content: context.TITE,
          editable: false,
          database: "doc",
          inputType: "text",
          showEmpty: false,
        },
        {
          name: "REFD",
          content: context.REFD,
          editable: false,
          database: "doc",
          inputType: "text",
          showEmpty: false,
        },
        {
          name: "REFE",
          content: context.REFE,
          editable: false,
          database: "doc",
          inputType: "text",
          showEmpty: false,
        },
        {
          name: "DNUMP",
          content: context.DNUMP,
          editable: false,
          database: "doc",
          inputType: "text",
          showEmpty: false,
        },
        {
          name: "GUAB",
          content: context.GUAB,
          editable: false,
          database: "doc",
          inputType: "text",
          showEmpty: false,
        },
      ];
    }

    const fieldList = computed(function () {
      const list = createFieldList(doc.value);
      const filteredList = list.filter(
        (item) => item.showEmpty || !!item.content?.length
      );
      return filteredList;
    });

    watch(doc, async function () {
      const response = await fetch(
        globals.coverURL + "/docs/getobj?obj=" + doc.value.PAGE1[0].e,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + store.getters.token,
          },
        }
      );
      let blob = await response.blob();
      image.value = URL.createObjectURL(blob);
    });

    async function downloadFile(id) {
      const response = await fetch(
        globals.coverURL + "/docs/getobj?obj=" + id,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + store.getters.token,
          },
        }
      );
      let blob = await response.blob();
      window.open(URL.createObjectURL(blob));
    }

    return {
      t,
      closeDetails,
      doc,
      toLocaleString,
      toLocaleDate,
      image,
      fieldList,
      downloadFile,
    };
  },
};
</script>
